import React, { useEffect } from "react";
import { get_general_notifications } from "../../../services/user_apis";
import { Link } from 'react-router-dom'

const Funzone = () => {
    const [generalNotificationData, setGeneralNotificationData] = React.useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    
    const generalNotificationList = async () => {
        let post_body = {
            user_role: user.user_role,
            classes: user.classes
        }
        const response = await get_general_notifications(post_body)
        if (response.code === 1) {
            setGeneralNotificationData(response.data)
        }
    }

    useEffect(() => {
        generalNotificationList();
    }, [])

    return (
        <div className="activity-box">
            <Link to="/funezone" state={{ activeTab: "gameTab", key: "game" }} className="game-activity funzone-game" >
                <h4 className={(generalNotificationData?.funzone?.game > 0) ? "notify" : ""}>Games</h4>
                {generalNotificationData && (generalNotificationData?.funzone?.game > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
            </Link>
            <Link to="/funezone" state={{ activeTab: "cartoonTab", key: "cartoon" }} className="game-activity funzone-cartoon" >
                <h4 className={(generalNotificationData?.funzone?.cartoon > 0) ? "notify" : ""}>Cartoons</h4>
                {generalNotificationData && (generalNotificationData?.funzone?.cartoon > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
            </Link>
            <Link to="/funezone" state={{ activeTab: "wellBeingTab", key: "well_being" }} className="game-activity funzone-wellBeing" >
                <h4 className={(generalNotificationData?.funzone?.well_being > 0) ? "notify" : ""}>Wellbeing</h4>
                {generalNotificationData && (generalNotificationData?.funzone?.well_being > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
            </Link>
            <Link to="/funezone" state={{ activeTab: "memesTab", key: "meme" }} className="game-activity funzone-memes" >
                <h4 className={(generalNotificationData?.funzone?.meme > 0) ? "notify" : ""}>Memes</h4>
                {generalNotificationData && (generalNotificationData?.funzone?.meme > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
            </Link>
            {/* <Link to="/funezone" state={{ activeTab: "codingTab", key: "coding" }} className="game-activity funzone-coding" >
                                                <h4 className={(generalNotificationData?.funzone?.coding > 0) ? "notify" : ""}>Coding</h4>
                                                {generalNotificationData && (generalNotificationData?.funzone?.coding > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                                            </Link> */}
            <Link to="/funezone" state={{ activeTab: "videoTab", key: "video" }} className="game-activity funzone-videos" >
                <h4 className={(generalNotificationData?.funzone?.video > 0) ? "notify" : ""}>Videos</h4>
                {generalNotificationData && (generalNotificationData?.funzone?.video > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
            </Link>
            <Link to="/funezone" state={{ activeTab: "musicTab", key: "music" }} className="game-activity funzone-musics" >
                <h4 className={(generalNotificationData?.funzone?.music > 0) ? "notify" : ""}>Musics</h4>
                {generalNotificationData && (generalNotificationData?.funzone?.music > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
            </Link>
        </div>
    );
};

export default Funzone;