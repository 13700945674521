import React, { useState } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../my-homework-teacher/HomeWorkDetails.css'
import { Link, useLocation } from 'react-router-dom';
import { submission_details, submission_details_update } from '../../../services/user_apis'
import moment from 'moment'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';

export default function SubmitWorkDetails({ socketData }) {
   const { state } = useLocation();
   const [homeWorkDetails, setHomeworkDetails] = React.useState();
   let navigate = useNavigate();

   React.useEffect(() => {
      // console.log("Call Home work details api")
      // console.log("Home Data", state)

      const submitedHomeWorkDetails = async () => {
         const post_body = {
            "submission_id": state.homworkData.submission_id
         }
         const response = await submission_details(post_body)

         if (response.code === 1) {
            // console.log("get Submited homework details data  ===> ", response)
            setHomeworkDetails(response.data)
         }
      }

      submitedHomeWorkDetails()
   }, [])

   const updateSubmitionDetails = async (body) => {
      try {

         const response = await submission_details_update(body)
         // console.log("Update Response", response)
         if (response.code === 1) {
            navigate(0)
         }
      }
      catch (e) {
         navigate('/')
      }
   }

   const buttonClickUpdateStatusAccepted = () => {
      var body = { "submission_id": state.homworkData.submission_id, "submission_status": "approved" }
      updateSubmitionDetails(body)
   }


   const buttonClickUpdateStatusRejected = () => {

      var selectDataInput = "";
      Swal.fire({
         title: 'Please Enter Rejection Reason',
         html: '<input type="text" name="hwSubmissionDate" id="hwSubmissionDate" placeholder="Rejection Reason" className="form-control" required/>',
         stopKeydownPropagation: false,
         preConfirm: () => {
            var selectDate = Swal.getPopup().querySelector('#hwSubmissionDate');
            if (selectDate.value === "") {
               Swal.showValidationMessage(`Please select Last Submission Date`)
            }
            selectDataInput = selectDate.value;
         },
      }).then((result) => {
         if (result.isConfirmed) {
            var body = { "submission_id": state.homworkData.submission_id, "submission_status": "rejected", "submission_remark": selectDataInput }
            updateSubmitionDetails(body)
         }
      })
   }


   return (
      <React.Fragment>
         <div className="content">
            <div className="left-portion">
               <Left_Panel />
            </div>
            <div className="middle-portion">
               <Home_Header socketData={socketData} />
               <div className="container-fluid">
                  <section className="post-section pt-4">
                     <div className="container">
                        <div className="row justify-content-center">
                           <div className="col-lg-12">
                              <div className="my-school">
                                 <div className="teacher-pro">
                                    <img src={homeWorkDetails?.student_profile} />
                                    <h2>{homeWorkDetails?.studen_name}</h2>
                                    <label className="posted">{moment.utc(homeWorkDetails?.created_at).local().format("DD MMMM, YYYY | h:mm a")}</label>
                                    <div className="academic-body mt-2">
                                       <label className="posted mb-0"><span>{state.homworkData.homework_title}</span></label>
                                       <h3 className="mb-1"><a >{state.homworkData.homework_question}</a></h3>
                                       <label className="submission-date">Homework Mode : {(state.homworkData.homework_type === "text") ? "Text Box" : (state.homworkData.homework_type === "file") ? "File" : "Text Box And File"}</label>
                                    </div>
                                    <div className="last-date-submission">
                                       <div className="score">
                                          <span className={`d-block  text-capitalize ${homeWorkDetails?.status === "rejected" ? 'text-danger' : 'text-success'}`}>{homeWorkDetails?.status}</span>
                                          {homeWorkDetails?.status === "result" && <span className={`  text-capitalize text-success`}>{homeWorkDetails?.mark} <span className={` text-capitalize text-secondary`}> / 100</span> </span>}
                                       </div>

                                    </div>
                                 </div>
                                 <div className="school-section mt-3">
                                    <div className="row justify-content-center pt-4">
                                       <div className="col-lg-12">
                                          <div className="academic-detail text-left">
                                             <p>{homeWorkDetails?.submission_answer}</p>
                                             <div className='row'>
                                                {homeWorkDetails?.submission_file_list && homeWorkDetails?.submission_file_list.map((item, index) => (
                                                   <a target="_blank" href={item.media_file_full_path} key={index}><div className='m-1'><img src={item.file_type === "pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : (item.file_type === "image") ? process.env.PUBLIC_URL + "/assets/images/file.png" : process.env.PUBLIC_URL + "/assets/images/doc.png"} style={{ width: 35, height: 35 }} /> {item.media} </div></a>
                                                ))}
                                             </div>
                                             {homeWorkDetails?.remark != "" &&
                                                <div className="remarks-box bg-light my-4">
                                                   <h5>Remarks</h5>
                                                   {homeWorkDetails?.status === "rejected" ? <p className="text-danger">{homeWorkDetails?.remark}</p>
                                                      : <p className="text-success">{homeWorkDetails?.remark}</p>}
                                                </div>
                                             }
                                             <div className="academic-group-btn">
                                                {
                                                   (homeWorkDetails?.status === "submitted" || homeWorkDetails?.status === "Submitted" || homeWorkDetails?.status === "resubmitted" || homeWorkDetails?.status === "Resubmitted") ?
                                                      <>
                                                         <a onClick={buttonClickUpdateStatusAccepted} className="cybersmart-btn m-1">Accept</a>
                                                         <a onClick={buttonClickUpdateStatusRejected} className="cybersmart-btn btn-grey" data-target="#Rejecthomework_Modal m-1" data-toggle="modal">Reject</a>
                                                      </>
                                                      :
                                                      (homeWorkDetails?.status === "approved") ?
                                                         <>
                                                            <a className="accepted-btn cybersmart-btn m-1"   ><i className="fas fa-check-circle"></i> Accepted</a>
                                                            <Link to="/home-work-submit-remarks" state={{ homworkData: homeWorkDetails }} className="cybersmart-btn btn-green m-1">Marks &amp; Remarks</Link>
                                                         </>
                                                         :
                                                         <>
                                                         </>
                                                }
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
               </div>
            </div>

         </div>
         <Modal />
      </React.Fragment>
   )
}
