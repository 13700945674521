import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { get_content_page_details } from '../../../services/user_apis'

const Privacy = ({ socketData }) => {
  const [privacyData, setPrivacyData] = React.useState([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    const contentDetails = async () => {
      let post_body = {
        page_type: "privacy_policy",
        language: "en"
      }
      const response = await get_content_page_details(post_body)
      if (response.code === 1) {
        setPrivacyData(response.data)
      }
    }
    contentDetails()
  }, [])


  return (
    <React.Fragment>
      <div className="content">
        <div className="left-portion">
          <Left_Panel />
        </div>
        <div className="middle-portion">
          <Home_Header socketData={socketData} />
          <div className="container-fluid">

            <section className="page-banner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="banner-title">{t('Privacy Policy')}</h2>
                    <div className="bread-crumbs">
                      <Link to="/home">{t('Home')}</Link> <span></span> {t('Privacy Policy')}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="pt-4">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="terms-condition text-left">
                      {privacyData != "" &&
                        <div className="term-box" dangerouslySetInnerHTML={{ __html: privacyData.content }} />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>


      </div>
      <Modal />
    </React.Fragment>
  )
}

export default Privacy
