import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

let selected_language = localStorage.getItem("language")

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: selected_language != "" ? selected_language : 'en',
  resources: {
    en: {
      translations: require('./locales/en/translation.json')
    },
    hin: {
      translations: require('./locales/hin/translation.json')
    },
    ar: {
      translations: require('./locales/ar/translation.json')
    },
    jap: {
      translations: require('./locales/jap/translation.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'hin', 'ar', 'jap'];

export default i18n;
