import React from 'react'
import { useTranslation } from 'react-i18next'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { get_faqs_list } from '../../../services/user_apis'

const Faq = ({ socketData }) => {
  const { t } = useTranslation();
  const [faqData, setFaqData] = React.useState([]);


  React.useEffect(() => {
    const contentDetails = async () => {
      const response = await get_faqs_list({  page: 1, limit: 100, sort_by: 'id', order_by: 'desc' })
      // console.log("get faq details ==> ", response.data.list)
      if (response.code === 1) {
        setFaqData(response.data.list)
      }
    }
    contentDetails()
  }, [])
  return (
    <React.Fragment>

      <div className="content">
        <div className="left-portion">
          <Left_Panel />
        </div>
        <div className="middle-portion">
          <Home_Header socketData={socketData} />
          <div className="container-fluid">

            <section className="page-banner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="banner-title">{t('FAQ')}</h2>
                    <div className="bread-crumbs">
                      <a href="index.php">{t('Home')}</a> <span></span> {t('Faq')}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="pt-4">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="faq-search">
                      <div className="form-group">
                        <input type="text" name="" placeholder={t('Search here...')} className="form-control" />
                        <div className="search-position">
                          <button className="search-icon"></button>
                        </div>
                      </div>
                    </div>

                    <div id="accordionExample" className="accordion faq-accoridan">

                      {faqData &&
                        faqData.map((item, index) => (
                          <div className="card text-left" key={index}>
                            <div id="headingOne" className="card-header bg-white border-0">
                              <h2 className="mb-0">
                                <button type="button" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="false"
                                  aria-controls={`collapse${index}`}
                                  className="btn  text-dark font-weight-bold text-uppercase collapsible-link">{index + 1}  {item?.en_question}</button>
                              </h2>
                            </div>
                            <div id={`collapse${index}`} aria-labelledby="headingOne" data-parent="#accordionExample" className="collapse ">
                              <div className="card-body ">
                                <p className="font-weight-light m-0">{item?.en_answer}</p>
                              </div>
                            </div>
                          </div>
                        ))
                      }

                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>
        </div>


      </div>
      <Modal />
    </React.Fragment>
  )
}

export default Faq