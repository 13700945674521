import React,{useState} from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../my-homework-teacher/SubmitMarks.css'
import Swal from 'sweetalert2'
import { Link, useLocation } from 'react-router-dom';
import {  useNavigate } from 'react-router-dom';
import { submission_details_update} from '../../../services/user_apis'


export default function RemarksSubmitWork({ socketData }) {
    const { state } = useLocation();
    let navigate = useNavigate();

    const checkCustom = ()=>{
        if(document.getElementById("customCheckbox9").checked){
            document.getElementById("customReason").disabled = false
        }else{
            document.getElementById("customReason").disabled = true
        }
    }
    setTimeout(()=>{
        document.getElementById("customCheckbox").checked=true
        document.getElementById("customReason").disabled = true
    },100)

    const updateSubmitionDetails = async (body) => {
        try {
           
            const response = await submission_details_update(body)
            // console.log("Update Response",response)
            if (response.code === 1) {
            navigate(-1)
           }
        }
        catch (e) {
            navigate('/')
        }
     }
     
    const buttonClickUpdateStatusRemark = ()=>{

       var marks = document.getElementById("marks").value.trim()
       
       var customeData = document.getElementById("customReason").value.trim()

       if(!document.getElementById("customCheckbox9").checked){
        let selectChecbox = document.getElementsByName('customCheck');
        selectChecbox.forEach((rate) => {
            if (rate.checked) {
                customeData = rate.value;
            }
        });
       }
       
       if(marks===""){
        Swal.fire(
            '',
            'Please Enter Marks',
            'error'
          )
       }else if(marks>=101) {
        Swal.fire(
            '',
            'Please Enter Valid Marks',
            'error'
          )
       }else if(document.getElementById("customCheckbox9").checked && customeData==="" ){
        Swal.fire(
            '',
            'Please Enter Custome Remarks',
            'error'
          )
       }else{
        var body={"submission_id": state.homworkData.submission_id,"submission_status":"result","submission_remark":customeData,"submission_mark":marks}
        // console.log(body)
        updateSubmitionDetails(body)
       }

     }
      return (
    <React.Fragment>
    <div className="content">
        <div className="left-portion">
            <Left_Panel />
        </div>
        <div className="middle-portion">
            <Home_Header socketData={socketData} />
            <div className="container-fluid">
                <section className="post-section pt-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                            <div className="my-school">
                                <div className="teacher-pro mark-remark-box">
                                    <img src={state.homworkData.student_profile }/>
                                    <h2>{state.homworkData.student_name}</h2>
                                    <h3>Marks &amp; Remarks</h3>
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="marks-form">
                                        <h4>Enter marks below</h4>
                                        <div className="form-group">
                                            <input type="number"  name="marks" id="marks" className="form-control" placeholder="Enter Marks" />
                                            <label>100</label>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 text-left">
                                        <div className="remark-list">
                                        <h4>Remarks</h4>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox" name="customCheck" className="custom-control-input"  onChange={checkCustom} value="Fantastic and Extraordinary performance! You are the topper. Keep up your learning spirit!"/>
                                            <label className="custom-control-label" for="customCheckbox">Fantastic and Extraordinary performance! You are the topper. Keep up your learning spirit!</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox1" name="customCheck" className="custom-control-input" onChange={checkCustom} value="Congratulations! You are one among the top performers. Great work!"/>
                                            <label className="custom-control-label" for="customCheckbox1">Congratulations! You are one among the top performers. Great work!</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox2" name="customCheck" className="custom-control-input" onChange={checkCustom} value="Great going and good commitment. You are an asset to the class"/>
                                            <label className="custom-control-label" for="customCheckbox2">Great going and good commitment. You are an asset to the class.</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox3" name="customCheck" className="custom-control-input" onChange={checkCustom} value="Good performance, keep trying, you are close to getting an A"/>
                                            <label className="custom-control-label" for="customCheckbox3">Good performance, keep trying, you are close to getting an A.</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox4" name="customCheck" className="custom-control-input" onChange={checkCustom} value="Well done but you know and I know you can do better. Keep trying" />
                                            <label className="custom-control-label" for="customCheckbox4">Well done but you know and I know you can do better. Keep trying.</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox5" name="customCheck" className="custom-control-input" onChange={checkCustom} value="Good going and decent performance. Keep improving your scores"/>
                                            <label className="custom-control-label" for="customCheckbox5">Good going and decent performance. Keep improving your scores.</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox6" name="customCheck" className="custom-control-input"  onChange={checkCustom} value="You passed, well done. With some more effort you will get honours, keep trying"/>
                                            <label className="custom-control-label" for="customCheckbox6">You passed, well done. With some more effort you will get honours, keep trying.</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox7" name="customCheck" className="custom-control-input" onChange={checkCustom} value="Just a few steps away from the pass mark. Keep trying. You can win next time!"/>
                                            <label className="custom-control-label" for="customCheckbox7">Just a few steps away from the pass mark. Keep trying. You can win next time!</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox9" name="customCheck" className="custom-control-input"  onChange={checkCustom}/>
                                            <label className="custom-control-label" for="customCheckbox9">Custom </label>
                                        </div>
                                        <div className="form-group mt-3">
                                            <textarea className="form-control form-radius border-0" rows="5" id="customReason" name="customReason" placeholder="Enter Remarks"></textarea>
                                        </div>
                                        <div className="form-group">
                                            <a onClick={buttonClickUpdateStatusRemark} className="cybersmart-btn btn-block">Submit Marks &amp; Remarks</a>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <Modal />
</React.Fragment>
)

}
