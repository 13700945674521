import React, { useState } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../my-homework-teacher/AddHomeWork.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { uploadImage } from "../../../services/imageUpload";
import { add_lecture, update_lecture } from '../../../services/user_apis'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export default function AddHomeWork({ socketData }) {
   let location = useLocation()
   const { t } = useTranslation();
   let { id } = useParams()
   var idList = id.split("&");

   let navigate = useNavigate();

   const type = idList[0]
   const classId = idList[1]
   const divisionId = idList[2]

   let user = JSON.parse(localStorage.getItem('user'));

   React.useEffect(() => {
      // console.log("locatoin data  ==> ", location.state)
      if (location.state != null) {

         let SECONDS = convertDurationtoSeconds(location.state.classData.lecture_time) + convertDurationtoSeconds(location.state.classData.lecture_duration)
         let endTime = new Date(SECONDS * 1000).toISOString().substring(11, 16)
         document.getElementById("Title").value = location.state.classData.lecture_subject_name
         document.getElementById("ClassDate").value = location.state.classData.lecture_date
         document.getElementById("Instruction").value = location.state.classData.lecture_instruction
         document.getElementById("Starttime").value = location.state.classData.lecture_time
         document.getElementById("Endtime").value = endTime
         document.getElementById("classLink").value = location.state.classData.lecture_link
      }
   }, [])
   function convertDurationtoSeconds(duration) {
      const [hours, minutes] = duration.split(':');
      return Number(hours) * 60 * 60 + Number(minutes) * 60;
   };

   const clickSubmitButton = async (e) => {
      e.preventDefault();

      let SECONDS = convertDurationtoSeconds(document.getElementById("Endtime").value) - convertDurationtoSeconds(document.getElementById("Starttime").value)
      let duration = new Date(SECONDS * 1000).toISOString().substring(11, 16)
      // console.log("sec", duration)
      const post_body = {
         lecture_duration: duration,
         lecture_subject_name: document.getElementById("Title").value,
         class_id: classId,
         division_id: divisionId,
         lecture_teacher_name: user?.display_user_name != "" ? user?.display_user_name : user.username,
         lecture_date: document.getElementById("ClassDate").value,
         lecture_time: document.getElementById("Starttime").value,
         lecture_datetime: new Date(),
         lecture_link: document.getElementById("classLink").value,
         lecture_instruction: document.getElementById("Instruction").value,
      }

      if (location?.state?.isEdit) {
         post_body["lecture_id"] = location.state.classData.lecture_id
         const response = await update_lecture(post_body)
         // console.log("create online class   ===> ", response)
         if (response.code === 1) {
            navigate(-1)
         }
      } else {
         const response = await add_lecture(post_body)
         // console.log("create online class   ===> ", response)
         if (response.code === 1) {
            navigate(-1)
         }
      }
      // return
   }

   return (
      <React.Fragment>
         <div className="content">
            <div className="left-portion">
               <Left_Panel />
            </div>
            <div className="middle-portion">
               <Home_Header socketData={socketData} />
               <div className="container-fluid">
                  <section className="post-section pt-4">
                     <div className="container">
                        <div className="row justify-content-center">
                           <div className="col-lg-10">
                              <div className="my-school">
                                 <div className="create-post">
                                    <div className="post-title text-center">
                                       {location.state != null && location.state.isEdit === true ? <h3>{('Edit Online Class')}</h3> : <h3>{t('Create Online Class')}</h3>}
                                    </div>
                                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                       <div className="class-form">
                                          <div className="form-group ">
                                             <p className='text-left ml-3 mb-1'>{t('Title of the Class *')}</p>
                                             <input type="text" name="TItle" id="Title" placeholder={t('Title of the Class *')} className="form-control" required />
                                          </div>
                                          <div className="form-group">
                                             <p className='text-left ml-3 mb-1'>{t('Instruction')}</p>
                                             <textarea className="form-control" name='Instruction' id='Instruction' placeholder={t('Instruction')} rows="5" required></textarea>
                                          </div>
                                          <div className='row'>
                                             <div className="form-group col-4">
                                                <p className='text-left ml-3 mb-1'>{t('Date of Class *')}</p>
                                                <input type="date" name="ClassDate" id="ClassDate" placeholder={t('Last Submission Date')} className="form-control datepicker" required />
                                             </div>
                                             <div className="form-group col-4">
                                                <p className='text-left ml-3 mb-1'>{t('Start Time *')}</p>
                                                <input required type="time" id="Starttime" name="Starttime" className="form-control datepicker" />
                                             </div>
                                             <div className="form-group col-4">
                                                <p className='text-left ml-3 mb-1'>{t('End Time *')}</p>
                                                <input required type="time" id="Endtime" name="Endtime" className="form-control datepicker" />
                                             </div>
                                          </div>
                                          <div className="form-group ">
                                             <p className='text-left ml-3 mb-1'> {t('Meeting Link *')}</p>
                                             <input type="text" name="classLink" id="classLink" placeholder={t('Enter Class Link')} className="form-control" required />
                                          </div>
                                          <div className="form-group text-center">
                                             <button className="cybersmart-btn-m btn-block" data-dismiss="modal" type='submit' >{location.state != null && location.state.isEdit === true ? t('Update') : t('Create Class')}</button>
                                          </div>
                                       </div>
                                    </form>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
               </div>

            </div>
            <div className="content">
               <div className="middle-portion">
               </div>
            </div>

         </div>
         <Modal />
      </React.Fragment>
   )
}
